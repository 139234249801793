import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

var mqtt = require('mqtt');
var client = mqtt.connect({
        host: 'iot.patizivs.lv',
        port: 8083,
        username: 'agris',
        password: 'sirg353a',
        protocol: 'wss'
    });

client.on('connect', function () {
  client.subscribe('test', function (err) {
    if (!err) {
      client.publish('test', 'Hello mqtt')
    }
  })
})

client.on('message', function (topic, message) {
  // message is Buffer
  console.log(message.toString()) 
  //client.end()
})

class App extends React.Component {
  switchOn() {
   client.publish('test', "switch on");
  }

  switchOff() {
    client.publish('test', "switch off");
  }

  status() {
    client.publish('test', "status");
  }
  render() {
  return (
    <div className="App">
      <header className="App-header">
        <Container>
         <Row>
           <Col>
            <h1> Lampa ir ieslēgta </h1>
              <Button onClick={this.status} variant="status" size="lg">Status</Button>
            </Col>
         </Row>
          <Row>
            <Col xs={6}>
              <Button onClick={this.switchOn} variant="success" size="lg">Ieslēgt</Button>
            </Col>
            <Col xs={6}>
              <Button onClick={this.switchOff} variant="danger" size="lg">Izslēgt</Button>
            </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
}
}

export default App;
